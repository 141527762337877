<template>
  <div class="addDiscount">
    <van-form v-if="orderData.jtoStatus == '处理中'">
      <van-field v-model="orderData.jtoApplicant" label="申请人：" readonly />
      <van-field v-model="orderData.jtoTotalHousingPrice" label="订单总价：" readonly />
      <van-field v-model="orderData.jtoDayPrice" label="日均房价：" readonly />
      <van-field v-model="orderData.jtoDiscount" label="折扣率：" readonly />
      <van-field v-model="orderData.jtoRoomType" label="房客房型：" readonly />
      <van-field v-model="orderData.jtoDiscountPrice" label="折扣后总价：" readonly />
      <van-field v-model="orderData.jtoAveragePrice" label="折扣后均价：" readonly />
      <van-field v-model="orderData.jtoAddress" label="房客地址：" readonly />
      <van-field v-model="orderData.jtoRemark" label="折扣原因：" type="textarea" rows="5" readonly />
      <div class="detailRow" style="margin: 16px;">
        <van-button @click="submit('同意')" block type="info">同意申请</van-button>
        <van-button @click="submit('不同意')" block type="info">拒绝申请</van-button>
      </div>
    </van-form>
    <van-form v-else class="carryOut">
      <div v-if="orderData.jtoStatus == '同意'">
        <van-image width="100" height="100" :src="agree" />
        <p>审批已同意</p>
      </div>
      <div v-if="orderData.jtoStatus == '不同意'">
        <van-image width="100" height="100" :src="reject" />
        <p>审批已拒绝</p>
      </div>
      <p>申请人：{{orderData.jtoApplicant}}</p>
      <p>审批时间：{{orderData.jtoAuthTime}}</p>
    </van-form>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: 'addDiscount',
  data () {
    return {
      orderData: {},
      urlArray: '',
      agree: require('../../assets/agree.png'),
      reject: require('../../assets/reject.png'),
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData() {
      this.$toast.loading({
        message: '拼命加载中...',
        duration: 0, // 持续展示 toast
        forbidClick: true,
      })
      let str = window.location.href
      this.urlArray  = str.split('?')[1].split('&').map(item => {
        item = item.split('=')[1]
        return item
      })
      this.$axios({
        method: 'get',
        url: `/hotel/temporaryOrder/${this.urlArray[1]}`,
        params: {
          co: this.urlArray[0],
        }
      }).then(res => {
        if (Object.keys(res.data.result).length > 0) {
          this.orderData = res.data.result
        } else {
          this.orderData = {}
        }
        this.$toast.clear();
      }).catch(err => {
        this.$toast.clear();
        this.$toast.fail(`${err.response.data.msg}`)
      })
    },
    submit(state) {
      this.$toast.loading({
        message: '拼命加载中...',
        duration: 0, // 持续展示 toast
        forbidClick: true,
      })
      this.$axios({
        url: `/hotel/temporaryOrder/${this.urlArray[1]}`,
        method: 'put',
        params: {
          co: this.urlArray[0],
          jtoAuthTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          jtoStatus: state,
        }
      }).then(res => {
        this.$toast.clear();
        if (state == '同意') {
          this.$toast.success(`已接受申请`)
        } else if (state == '不同意') {
          this.$toast.fail(`已拒绝申请`)
        }
        this.getData()
      }).catch(err => {
        this.$toast.clear();
        this.$toast.fail(`${err.response.data.msg}`)
      })
    },
  }

}
</script>

<style lang="scss">
  .addDiscount {
    .van-field__label{
      width: 8rem;
    }
    .detailRow{
      .van-button--normal{
        width: 8rem;
        font-size: 1.1rem;
      }
    }
  }
</style>
<style lang="scss" scoped>
  .addDiscount {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    .van-cell{
      // padding: 0.3rem 0.8rem;
      font-size: 1.1rem;
    }
    .detailRow{
      display: flex;
      justify-content: space-around;
      flex-flow: wrap;
    }
    .carryOut{
      text-align: center;
      position: relative;
      top: 35%; /*偏移*/
      transform: translateY(-50%);
    }
  }
</style>